import {
  BarController,
  BarElement,
  CategoryScale, // eslint-disable-next-line import/named
  ChartData,
  Chart as ChartJS,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { ReportOverviewResponse } from 'common/api/multimap';
import { ConditionDegreeIcon } from 'common/components/ConditionDegreeIcon/ConditionDegreeIcon';
import { HelpPopover } from 'common/components/HelpPopover/HelpPopover';
import { TableContainer } from 'common/components/TableContainer/TableContainer';
import { arrayRange } from 'common/helpers/array.helpers';
import { asNumberSeperator } from 'common/helpers/numberHelpers';
import { LoadingSpinner } from 'features/admin/components/loading-spinner/LoadingSpinner';
import useGetReportOverview from 'features/user/home/hooks/useGetReportOverview';
import { useEffect, useState } from 'react';
import { Button, Card, Col, ProgressBar, Row, Stack, Table } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import { useNavigate } from 'react-router';

import useGetBasisReportPeriodByYear from '../hooks/useGetBasisReportPeriodByYear';

export interface IProps {
  workspaceModuleId: string;
}

export const ReportCardPeriodOverTimeReport: React.FC<IProps> = ({ workspaceModuleId }) => {
  const navigate = useNavigate();

  const [isLoading, , data] = useGetBasisReportPeriodByYear(workspaceModuleId);

  ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'bottom' as const,
      },
    },
    maintainAspectRatio: false,
    scales: {
      tg: {
        position: "right" as const,
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        }
      }
    }
  };

  const [chartData, setChartData] = useState<ChartData<'bar', number[], string>>({
    labels: [''],
    datasets: [{ label: '', data: [0], backgroundColor: ['#14BE14'], order: 1, type: 'bar', yAxisID: "areal" }],
  });

  useEffect(() => {
    if (data) {
      console.log(data);
      const labels: string[] = [];

      data.map((row) => {
        labels.push(row.periodName);
      });

      const color = (value: number) => {
        if (value < 0.75) {
          return '#14BE14';
        } else if (value >= 0.75 && value < 1.5) {
          return '#B8E926';
        } else if (value >= 1.5 && value < 2.25) {
          return '#FAAB35';
        } else {
          return '#FF0000';
        }
      };

      const TG0values = labels.map(() => 0);
      const TG1values = labels.map(() => 0);
      const TG2values = labels.map(() => 0);
      const TG3values = labels.map(() => 0);

      const collectedCds = labels.map(() => 0);

      data.map((reportOverview) => {
        const period = reportOverview.periodName;
        const index = labels.findIndex((x) => x == period);

        reportOverview.reportObjects.map((row, i) => {
          if (row.averageWTCD < 0.75) {
            TG0values[index] += row.areal;
          } else if (row.averageWTCD >= 0.75 && row.averageWTCD < 1.5) {
            TG1values[index] += row.areal;
          } else if (row.averageWTCD >= 1.5 && row.averageWTCD < 2.25) {
            TG2values[index] += row.areal;
          } else {
            TG3values[index] += row.areal;
          }
        });

        collectedCds[index] = reportOverview.collectedCD;
      });

      setChartData({
        labels: labels,
        datasets: [
          {
            label: 'TG 0',
            data: TG0values,
            backgroundColor: [color(0)],
            order: 1,
            type: 'bar' as const,
            yAxisID: "areal"
          },
          {
            label: 'TG 1',
            data: TG1values,
            backgroundColor: [color(1)],
            order: 1,
            type: 'bar' as const,
            yAxisID: "areal"
          },
          {
            label: 'TG 2',
            data: TG2values,
            backgroundColor: [color(2)],
            order: 1,
            type: 'bar' as const,
            yAxisID: "areal"
          },
          {
            label: 'TG 3',
            data: TG3values,
            backgroundColor: [color(3)],
            order: 1,
            type: 'bar' as const,
            yAxisID: "areal"
          },
          {
            label: 'Vektet tilstandsgrad',
            data: collectedCds,
            backgroundColor: ['#063342'],
            order: 0,
            type: 'line' as any,
            yAxisID: "tg"
          },
        ],
      });
    }
  }, [data]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      {data && (
        <>
          <div className="d-flex justify-content-between">
            <h4>Utvikling over tid</h4>
            <HelpPopover
              id="barchart-1desimal"
              type="info"
              title="Barchart 1-desimal"
              text="Grafen viser fordelingen av mengden som er blitt kartlagt, rundet av til nærmeste tilstandgrad med én desimal. Dette gir en visuell representasjon av hvordan dataene er fordelt mellom de forskjellige tilstandene, med en nøyaktighet på ett desimalpunkt."
            />
          </div>
          <Card className="p-3">
            <Row>
              <Col>
                <div style={{ maxHeight: '500px', minHeight: '500px' }} className="pb-3">
                  <h4>Oversikt</h4>
                  <Bar options={options} data={chartData} />
                </div>
              </Col>
            </Row>
          </Card>

          <Row className="my-3">
            <Col>
              <TableContainer>
                <Table hover responsive className="border-1">
                  <thead>
                    <tr>
                      <th scope="col">Kartlagt periode</th>
                      <th scope="col">Areal</th>
                      <th scope="col">Antall bygg</th>
                      <th scope="col">Gjennomsnittlig byggår</th>
                      <th scope="col">Samlet VTGG</th>
                      <th scope="col">Antall TG 3</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((row, i) => {
                      const sumYear = row.reportObjects.reduce((a, b) => a + b.year, 0);
                      const avgYear = sumYear / row.reportObjects.length;
                      return (
                        <tr>
                          <td>{row.periodName}</td>
                          <td>{asNumberSeperator(row.reportObjects.reduce((a, b) => a + b.areal, 0))} m2</td>
                          <td>{row.mappingObjectsAmountDone}</td>
                          <td>{avgYear.toFixed(2).replace(/[.,]00$/, '')}</td>
                          <td>
                            <ConditionDegreeIcon conditionDegree={row.collectedCD} />
                            {row.collectedCD.toFixed(2).replace(/[.,]00$/, '')}
                          </td>
                          <td>{row.reportObjects.filter((x) => x.averageWTCD >= 2.25).length}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </TableContainer>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
