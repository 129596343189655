import {
  AddCircleOutline,
  ArrowForward,
  ArrowRight,
  Check,
  Close,
  CloudSyncOutlined,
  FileDownload,
  SearchOutlined,
  UploadFileOutlined,
} from '@mui/icons-material';
import { maintenancePlansApi } from 'common/api/MultimapClients';
import { Pill } from 'common/components/Pill/Pill';
import { TileButton } from 'common/components/TileButton/TileButton';
import { PageTitle } from 'common/components/page-title/PageTitle';
import { asDateTime } from 'common/helpers/dateHelpers';
import { downloadFile } from 'common/helpers/http-header.helper';
import { asNumberSeperator } from 'common/helpers/numberHelpers';
import { notify } from 'common/helpers/toast-notification-helper';
import { LoadingSpinner } from 'features/admin/components/loading-spinner/LoadingSpinner';
import useGetWorkspaceModule from 'features/user/mapping/hooks/useGetWorkspaceModule';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Form, InputGroup, Modal, Row, Stack } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router';

import { UpdateMaintenancePlanModal } from '../components/UpdateMaintenancePlanModal';
import useGetMaintenancePlans from '../hooks/useGetMaintenancePlans';
import useImportMaintenancePlans from '../hooks/useImportMaintenancePlans';
import { MaintenancePlanResponse } from 'common/api/multimap';
import useRemoveMaintenancePlan from '../hooks/useRemoveMaintenancePlan';

type ImportStep = 'import-export' | 'export' | 'import' | 'import-loading';

export const MaintenancePlanOverview = () => {
  const { workspaceModuleId } = useParams();

  const [, , data] = useGetMaintenancePlans(workspaceModuleId ?? '');

  const [, , module] = useGetWorkspaceModule(workspaceModuleId ?? '');

  const [, , , , remove] = useRemoveMaintenancePlan();

  const navigate = useNavigate();

  const [sumCalculate, setSumCalculate] = useState<number>(0);

  const [sumEstimate, setSumEstimate] = useState<number>(0);

  const [maintenancePlanId, setMaintenancePlanId] = useState<string>('');

  const [maintenancePlan, setMaintenancePlan] = useState<MaintenancePlanResponse>();

  const [show, setShow] = useState<boolean>(false);

  const [step, setStep] = useState<ImportStep>('import-export');

  const [showExportImport, setShowExportImport] = useState<boolean>(false);

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const [searchActive, setSearchActive] = useState<string>("");
  const [searchFinished, setSearchFinished] = useState<string>("");

  useEffect(() => {
    if (data) {
      let newSumCalculate = 0;
      data
        .filter((x) => x.active)
        .map((row, i) => {
          newSumCalculate += row.totalPriceCalculate!;
        });
      setSumCalculate(newSumCalculate);

      let newSumEstimate = 0;
      data
        .filter((x) => x.active)
        .map((row, i) => {
          newSumEstimate += row.totalPriceEstimate!;
        });
      setSumEstimate(newSumEstimate);
    }
  }, [data]);

  const downloadMaintenancePlanExport = async () => {
    try {
      const response = await maintenancePlansApi.maintenancePlansWorkspaceModuleIdExportGet(workspaceModuleId ?? '', {
        responseType: 'blob',
      });

      downloadFile(response);

      notify('success', 'Eksport fil lagret');
    } finally {
      console.log('DONE');
    }
  };

  //Export Import Functions
  const [isSaving, , , , validationError, importExecuteResponse, saveAction] = useImportMaintenancePlans();

  const [importFile, setImportFile] = useState<File>();

  const isValid = (importFile && importFile.name.endsWith('.xlsx') && importFile.size > 0) ?? false;

  const onFileChanged = (files: FileList | null) => {
    const hasSingleFile = files?.length === 1;
    if (!hasSingleFile) {
      setImportFile(undefined);
      return;
    }

    setImportFile(files[0]);
  };

  return (
    <>
      {data && (
        <>
          <PageTitle title={`Planoversikt for ${module?.systemName}`} backTo="/maintenance-plan">
            <Button className="me-2" variant="tertiary" onClick={() => setShowExportImport(true)}>
              <CloudSyncOutlined /> Importer/eksporter vedlikeholdsplaner
            </Button>
            {data.length > 0 ? (
              <Button variant="tertiary" onClick={() => navigate(`/maintenance-plan/${workspaceModuleId}/create`)}>
                Lag nytt tiltak <AddCircleOutline />
              </Button>
            ) : (
              <></>
            )}
          </PageTitle>
          <h4 className="mt-3">Oversikt</h4>
          {data.length > 0 ? (
            <>
              <Card className="p-3 mb-4">
                <Row>
                  <Col>
                    <small>Total estimert kostnad</small>
                    <h5>{asNumberSeperator(sumEstimate)} kr</h5>
                  </Col>
                  <Col>
                    <small>Total kalkulert kostnad</small>
                    <h5>{asNumberSeperator(sumCalculate)} kr</h5>
                  </Col>
                  <Col className="d-flex justify-content-end">
                    <Button
                      variant="tertiary"
                      onClick={() => navigate(`/maintenance-plan/${workspaceModuleId}/details`)}
                    >
                      Se detaljoversikt
                    </Button>
                  </Col>
                </Row>
              </Card>
              <h4>Aktive tiltak</h4>
              <InputGroup className="mb-2">
                <InputGroup.Text>
                  <SearchOutlined fontSize="small" />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  id="filter"
                  placeholder={"Søk på aktive tiltak"}
                  aria-describedby={"Søk på aktive tiltak"}
                  onChange={(e) => setSearchActive(e.target.value)}
                />
              </InputGroup>
              {data.filter((x) => x.active).length > 0 ? (
                data
                  .filter((x) => x.active)
                  .filter((x) => x.maintenancePlanName.toLowerCase().includes(searchActive.toLowerCase()))
                  .map((row, i) => {
                    return (
                      <Card className="p-3 mb-4">
                        <Row>
                          <Col>
                            <h4>{row.maintenancePlanName}</h4>
                          </Col>
                          <Col className="d-flex justify-content-end">
                            <Pill variant="success">Sist endret {asDateTime(row.lastUpdated!)}</Pill>
                          </Col>
                        </Row>
                        <Row>
                          <p>{row.maintenancePlanDescription}</p>
                        </Row>
                        <Row>
                          <Col>
                            <Stack direction="vertical">
                              <small>{row.objectTypeName}</small>
                              <span>{row.objectName}</span>
                            </Stack>
                          </Col>

                          <Col>
                            <Stack direction="vertical">
                              <small>{row.objectTypeParentName}</small>
                              <span>{row.objectParentName}</span>
                            </Stack>
                          </Col>
                          <Col>
                            <Stack direction="vertical">
                              <small>Tiltakstype</small>
                              <span>{row.maintenancePlanTypeId}</span>
                            </Stack>
                          </Col>
                          <Col>
                            <Stack direction="vertical">
                              <small>Konsekvensgrad</small>
                              <span>{row.consequenceDegree}</span>
                            </Stack>
                          </Col>
                          <Col>
                            <Stack direction="vertical">
                              <small>Estimert kostnad</small>
                              <span>{asNumberSeperator(row.totalPriceEstimate)} kr</span>
                            </Stack>
                          </Col>
                          <Col>
                            <Stack direction="vertical">
                              <small>Kalkulert kostnad</small>
                              <span>{asNumberSeperator(row.totalPriceCalculate)} kr</span>
                            </Stack>
                          </Col>
                          <Col className="d-flex justify-content-end align-items-end">
                            <Button
                              variant="tertiary"
                              className="text-nowrap me-2"
                              onClick={() => {
                                setMaintenancePlan(row);
                                setShowDeleteModal(true);
                              }}
                            >
                              Slett tiltak
                            </Button>
                            <Button
                              variant="tertiary"
                              className="text-nowrap"
                              onClick={() => {
                                setMaintenancePlanId(row.maintenancePlanId!);
                                setShow(true);
                              }}
                            >
                              Rediger tiltak
                            </Button>
                          </Col>
                        </Row>
                      </Card>
                    );
                  })
              ) : (
                <Row className="g-0 rounded pt-3 mb-5" style={{ border: '1px dashed #ABBED1' }}>
                  <>
                    <Col xs={8} className="p-3">
                      <h4>Du har ingen aktive tiltak</h4>
                      <span>Trykk på "Lag nytt tiltak" knappen for å opprette nytt tiltak</span>
                    </Col>
                  </>
                </Row>
              )}
              <h4>Avsluttede tiltak</h4>
              <InputGroup className="mb-2">
                <InputGroup.Text>
                  <SearchOutlined fontSize="small" />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  id="filter"
                  placeholder={"Søk på avsluttede tiltak"}
                  aria-describedby={"Søk på avsluttede tiltak"}
                  onChange={(e) => setSearchFinished(e.target.value)}
                />
              </InputGroup>
              {data.filter((x) => !x.active).length > 0 ? (
                data
                  .filter((x) => !x.active)
                  .filter((x) => x.maintenancePlanName.toLowerCase().includes(searchFinished.toLowerCase()))
                  .map((row, i) => {
                    return (
                      <Card className="p-3 mb-4">
                        <Row>
                          <Col>
                            <h4>{row.maintenancePlanName}</h4>
                          </Col>
                          <Col className="d-flex justify-content-end">
                            <Pill variant="success">Sist endret {asDateTime(row.lastUpdated!)}</Pill>
                          </Col>
                        </Row>
                        <Row>
                          <p>{row.maintenancePlanDescription}</p>
                        </Row>
                        <Row>
                          <Col>
                            <Stack direction="vertical">
                              <small>{row.objectTypeName}</small>
                              <span>{row.objectName}</span>
                            </Stack>
                          </Col>

                          <Col>
                            <Stack direction="vertical">
                              <small>{row.objectTypeParentName}</small>
                              <span>{row.objectParentName}</span>
                            </Stack>
                          </Col>
                          <Col>
                            <Stack direction="vertical">
                              <small>Tiltakstype</small>
                              <span>{row.maintenancePlanTypeId}</span>
                            </Stack>
                          </Col>
                          <Col>
                            <Stack direction="vertical">
                              <small>Konsekvensgrad</small>
                              <span>{row.consequenceDegree}</span>
                            </Stack>
                          </Col>
                          <Col>
                            <Stack direction="vertical">
                              <small>Estimert kostnad</small>
                              <span>{asNumberSeperator(row.totalPriceEstimate)} kr</span>
                            </Stack>
                          </Col>
                          <Col>
                            <Stack direction="vertical">
                              <small>Kalkulert kostnad</small>
                              <span>{asNumberSeperator(row.totalPriceCalculate)} kr</span>
                            </Stack>
                          </Col>
                          <Col className="d-flex justify-content-end align-items-end">
                            <Button
                              variant="tertiary"
                              className="text-nowrap me-2"
                              onClick={() => {
                                setMaintenancePlan(row);
                                setShowDeleteModal(true);
                              }}
                              disabled={false}
                            >
                              Slett tiltak
                            </Button>
                            <Button
                              variant="tertiary"
                              className="text-nowrap"
                              onClick={() => {
                                setMaintenancePlanId(row.maintenancePlanId!);
                                setShow(true);
                              }}
                            >
                              Rediger tiltak
                            </Button>
                          </Col>
                        </Row>
                      </Card>
                    );
                  })
              ) : (
                <Row className="g-0 rounded pt-3 mb-5" style={{ border: '1px dashed #ABBED1' }}>
                  <>
                    <Col xs={8} className="p-3">
                      <h4>Du har ingen avsluttede tiltak</h4>
                      <span>For å avslutte et tiltak må alle kostnadspostene på et tiltak være fullført.</span>
                    </Col>
                  </>
                </Row>
              )}
            </>
          ) : (
            <>
              <Row className="g-0 rounded pt-3 mb-5" style={{ border: '1px dashed #ABBED1' }}>
                <>
                  <Col xs={8} className="p-3">
                    <h4>Du har ikke gjennomført ett tiltak for denne modulen</h4>
                    <span>
                      Trykk på “Lag ny plan” for å opprette ditt første tiltak. Dersom dette er gjennomført vil du kunne
                      se en oversikt og plandetaljene.
                    </span>
                  </Col>
                  <Col xs={4} className="d-flex justify-content-end align-items-end p-3">
                    <Button
                      variant="tertiary"
                      onClick={() => navigate(`/maintenance-plan/${workspaceModuleId}/create`)}
                    >
                      Lag nytt tiltak <AddCircleOutline />
                    </Button>
                  </Col>
                </>
              </Row>
            </>
          )}
        </>
      )}
      <UpdateMaintenancePlanModal
        show={show}
        onHide={() => setShow(false)}
        maintenancePlanId={maintenancePlanId}
        moduleName={module ? module.systemName : ''}
        workspaceModuleId={module ? module.workspaceModuleId : ''}
      />
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered={true} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Slett tiltak {maintenancePlan?.maintenancePlanName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Du vil nå slette tiltaket <b>{maintenancePlan?.maintenancePlanName}</b>.
            Dersom dette tiltaket er gjennomført og skal avsluttes gjøres dette gjennom "Rediger tiltak" {<ArrowRight />} "Avslutt post" <br></br> Er du sikker på at du vil slette denne? <br></br>Merk: Man kan ikke angre etter man har slettet et tiltak.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="tertiary"
            onClick={() => {
              setShowDeleteModal(false)
            }}
          >
            Avbryt
          </Button>
          <Button variant="primary" onClick={() => {
            if (maintenancePlan != null) {
              remove({ id: maintenancePlan.maintenancePlanId! });
            }
            setShowDeleteModal(false);
          }}>
            Slett tiltak
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showExportImport} onHide={() => setShowExportImport(false)} centered={true} size="lg">
        {step == 'import-export' && (
          <>
            <Modal.Header closeButton>
              <Modal.Title>Import og eksport</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="mb-3">
                <TileButton
                  leftIcon={<FileDownload fontSize="large" />}
                  rightIcon={<ArrowForward fontSize="large" />}
                  title="Last ned Excel fil med dine vedlikeholdsplaner"
                  text="Du får en excel fil med dine vedlikeholdsplaner. Du får også et ark til med dine fullførte kartleggingsobjekter som kan importeres inn."
                  onClick={() => downloadMaintenancePlanExport()}
                />
              </div>
              <div className="mb-3">
                <TileButton
                  leftIcon={<UploadFileOutlined fontSize="large" />}
                  rightIcon={<ArrowForward fontSize="large" />}
                  title="Importer data fra Excel"
                  text="Basert på excel filen du har eksportert kan du laste inn dine vedlikeholdsplaner."
                  onClick={() => setStep('import')}
                />
              </div>
            </Modal.Body>
          </>
        )}
        {step == 'import' && (
          <>
            <Modal.Header closeButton>
              <Modal.Title>Import av vedlikholdsplaner</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <>
                <Row>
                  <Col>
                    <Form.Label id="choose-file">Velg en excel fil å importere</Form.Label>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {importFile && (
                      <InputGroup size="sm">
                        <Form.Control
                          type="text"
                          value={importFile.name}
                          readOnly={true}
                          aria-labelledby="choose-file"
                        />
                        <Button
                          type="button"
                          variant="primary"
                          size="sm"
                          aria-label="Close"
                          onClick={() => setImportFile(undefined)}
                        >
                          <Close />
                        </Button>
                      </InputGroup>
                    )}
                    {!importFile && (
                      <Form.Control
                        type="file"
                        size="sm"
                        aria-labelledby="choose-file"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onFileChanged(e.target.files)}
                      />
                    )}
                  </Col>
                  <Col>
                    {importFile && (
                      <Button
                        type="submit"
                        variant="primary"
                        size="sm"
                        aria-label="Import"
                        className="text-nowrap"
                        disabled={!isValid}
                        onClick={() => {
                          setStep('import-loading');
                          saveAction({ workspaceModuleId: workspaceModuleId ?? '', file: importFile });
                        }}
                      >
                        Fullfør import
                      </Button>
                    )}
                  </Col>
                </Row>
              </>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="tertiary" onClick={() => setStep('import-export')}>
                Tilbake
              </Button>
            </Modal.Footer>
          </>
        )}
        {step == 'import-loading' && (
          <>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              {isSaving ? (
                <>
                  <h4>Oppdaterer vedlikeholdsplanene dine</h4>
                  <LoadingSpinner isLoading={isSaving} />
                </>
              ) : (
                <div className="d-flex justify-content-center align-items-center flex-column">
                  <Check color="success" />
                  <h4>Vedlikeholdsplaner oppdatert</h4>
                  <p>Import av vedlikeholdsplan var vellykket. Du kan nå se planene dine i oversikten.</p>
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={() => {
                  setShowExportImport(false);
                  setStep('import-export');
                }}
              >
                Gå til planoversikt
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </>
  );
};
