import { MappingPeriodResponse, WorkspaceResponse } from 'common/api/multimap';
import { PageTitle } from 'common/components/page-title/PageTitle';
import { asNumberSeperator } from 'common/helpers/numberHelpers';
import { useLocalStorage } from 'common/hooks/useLocalStorage';
import { ReportCardOverview } from 'features/user/home/components/ReportCardOverview';
import useGetObjectsOverview from 'features/user/home/hooks/useGetObjectsOverview';
import useGetClosedMappingPeriods from 'features/user/mapping/hooks/useGetClosedMappingPeriods';
import useGetWorkspaceModule from 'features/user/mapping/hooks/useGetWorkspaceModule';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useParams } from 'react-router';
import Select from 'react-select';

import { ReportCardBarArealYearBasisReport } from './components/ReportCardBarArealYearBasisReport';
import { ReportCardBarCDArealBasisReport } from './components/ReportCardBarCDArealBasisReport';
import { ReportCardPeriodOverTimeReport } from './components/ReportCardPeriodOverTimeReport';
import { ReportCardPieBasisReport } from './components/ReportCardPieBasisReport';
import useGetBasisReport from './hooks/useGetBasisReport';

interface Option {
  value: string;
  label: string;
}
export const BasisReport = () => {
  const { workspaceModuleId } = useParams();
  const [workspace] = useLocalStorage<WorkspaceResponse | undefined>('workspace', undefined);

  const [isLoading, , data] = useGetWorkspaceModule(workspaceModuleId ? workspaceModuleId : '');

  const [periodLoading, , closedPeriods] = useGetClosedMappingPeriods(workspaceModuleId ? workspaceModuleId : '');

  const [, , objectsOverview] = useGetObjectsOverview(workspace?.workspaceId ?? '');

  const [parents, setParents] = useState<Option[]>([]);

  const [children, setChildren] = useState<Option[]>([]);

  const [activePeriod, setActivePeriod] = useState<string>('');

  const [activeParents, setActiveParents] = useState<Option[]>([]);

  const [activeChildren, setActiveChildren] = useState<Option[]>([]);

  const [mappingObjectIds, setMappingObjectIds] = useState<string[]>([]);

  const [reportLoading, , report, fetch] = useGetBasisReport(activePeriod, mappingObjectIds);

  console.log(report);

  const getParentsAndChildren = (period: MappingPeriodResponse) => {
    if (period.mappingObjects) {
      //Parents
      const tempParents: Option[] = [];

      period.mappingObjects.map((row, i) => {
        if (!tempParents.some((x) => x.value == row.objectParentId)) {
          tempParents.push({ value: row.objectParentId, label: row.objectParentName });
        }
      });

      tempParents.sort((a, b) => {
        const nameA = a.label.toUpperCase();
        const nameB = b.label.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });

      setParents(tempParents);

      //Children
      const tempChildren: Option[] = [];
      period.mappingObjects.map((row, i) => {
        if (activeParents.length > 0) {
          if (activeParents.some((x) => x.value == row.objectParentId)) {
            tempChildren.push({ value: row.mappingObjectId, label: row.objectName });
          }
        } else {
          tempChildren.push({ value: row.mappingObjectId, label: row.objectName });
        }
      });

      tempChildren.sort((a, b) => {
        const nameA = a.label.toUpperCase();
        const nameB = b.label.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });

      setChildren(tempChildren);
    }
  };

  useEffect(() => {
    if (closedPeriods) {
      if (!activePeriod) {
        setActivePeriod(closedPeriods[0].mappingPeriodId);
        getParentsAndChildren(closedPeriods[0]);
      } else {
        setActiveParents([]);
        setActiveChildren([]);

        const index = closedPeriods.findIndex((x) => x.mappingPeriodId == activePeriod);
        getParentsAndChildren(closedPeriods[index]);
      }
    }
  }, [activePeriod, closedPeriods]);

  useEffect(() => {
    if (closedPeriods) {
      if (!activePeriod) {
        setActivePeriod(closedPeriods[0].mappingPeriodId);
        getParentsAndChildren(closedPeriods[0]);
      } else {
        setActiveChildren([]);

        const index = closedPeriods.findIndex((x) => x.mappingPeriodId == activePeriod);
        getParentsAndChildren(closedPeriods[index]);
      }
    }
  }, [activeParents]);

  const generateReport = () => {
    const tempMappingObjectIds: string[] = [];

    if (activeChildren.length > 0) {
      activeChildren.map((row, i) => {
        tempMappingObjectIds.push(row.value);
      });
    } else {
      if (activeParents.length > 0) {
        activeParents.map((row, i) => {
          if (closedPeriods) {
            const index = closedPeriods.findIndex((x) => x.mappingPeriodId == activePeriod);
            closedPeriods[index].mappingObjects?.map((mo, moIndex) => {
              if (row.value == mo.objectParentId) {
                tempMappingObjectIds.push(mo.mappingObjectId);
              }
            });
          }
        });
      }
    }

    setMappingObjectIds(tempMappingObjectIds);
  };

  useEffect(() => {
    if (activePeriod) {
      fetch();
    }
  }, [mappingObjectIds, activePeriod]);

  return (
    <>
      <PageTitle title={`Basisrapport for ${data?.systemName}`} backTo={'/report'} />
      <Row className="my-4">
        <Col>
          <Form.Select onChange={(e) => setActivePeriod(e.currentTarget.value)}>
            {closedPeriods &&
              closedPeriods.map((row, i) => <option value={row.mappingPeriodId}>{row.mappingPeriodName}</option>)}
          </Form.Select>
        </Col>
        <Col>
          <Select
            isMulti
            isSearchable
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                minHeight: '42px',
              }),
            }}
            placeholder={`Velg ${closedPeriods && closedPeriods[0].objectTypeParentName.toLowerCase()}`}
            options={parents}
            onChange={(e) => setActiveParents(e as Option[])}
            value={activeParents}
          />
        </Col>
        <Col>
          <Select
            isMulti
            isSearchable
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                minHeight: '42px',
              }),
            }}
            placeholder={`Velg ${closedPeriods && closedPeriods[0].objectTypeName.toLowerCase()}`}
            options={children}
            onChange={(e) => setActiveChildren(e as Option[])}
            value={activeChildren}
          />
        </Col>
        <Col md={2} className="d-flex justify-content-end">
          <Button variant="tertiary" className="text-nowrap" onClick={() => generateReport()}>
            Avgrens rapport
          </Button>
        </Col>
      </Row>
      <h3>Rapport for valgt Periode</h3>
      <Row>
        <Col>
          {objectsOverview && objectsOverview && (
            <Card className="p-3 mb-5">
              <Row>
                {objectsOverview.firstObjectName && (
                  <Col>
                    <h4>{objectsOverview.firstObjectName}</h4>
                    <h3>{objectsOverview.firstObjectAmount}</h3>
                  </Col>
                )}

                {objectsOverview.secondObjectName && (
                  <Col>
                    <h4>{objectsOverview.secondObjectName}</h4>
                    <h3>{objectsOverview.secondObjectAmount}</h3>
                  </Col>
                )}

                {objectsOverview.thirdObjectName && (
                  <Col>
                    <h4>{objectsOverview.thirdObjectName}</h4>
                    <h3>{objectsOverview.thirdObjectAmount}</h3>
                  </Col>
                )}

                <Col>
                  <h4>Bruttoareal</h4>
                  <h3>{asNumberSeperator(objectsOverview.areal)} m2</h3>
                </Col>
                {objectsOverview.year > 0 && (
                  <Col>
                    <h4 className="text-nowrap">Gjennomsnittlig byggår</h4>
                    <h3>{objectsOverview.year.toFixed(0)}</h3>
                  </Col>
                )}
              </Row>
            </Card>
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={4}>
          <ReportCardPieBasisReport reportOverview={report} isLoading={reportLoading} />
        </Col>
        <Col xs={8}>
          <ReportCardBarArealYearBasisReport reportOverview={report} isLoading={reportLoading} />
          {/* <ReportCardBarCDArealBasisReport reportOverview={report} isLoading={reportLoading} /> */}
        </Col>
      </Row>
      {/* <Row className="mt-5">
        <Col>
          {objectsOverview && objectsOverview.year > 0 && (
            <ReportCardBarArealYearBasisReport reportOverview={report} isLoading={reportLoading} />
          )}
        </Col>
      </Row> */}
      <Row className="mt-5">
        <Col>
          <ReportCardPeriodOverTimeReport workspaceModuleId={workspaceModuleId ?? ''} />
        </Col>
      </Row>
    </>
  );
};
