import { ArrowDropDown, CancelOutlined, SaveOutlined } from '@mui/icons-material';
import { CreateMaintenancePlanRequest, MaintenancePlanType, UpdateMaintenancePlanRequest } from 'common/api/multimap';
import { ConditionDegreeIcon } from 'common/components/ConditionDegreeIcon/ConditionDegreeIcon';
import { arrayRange } from 'common/helpers/array.helpers';
import useGetMappingObjectDetail from 'features/user/mapping/hooks/useGetMappingObjectDetail';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import { useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import useAddMaintenancePlan from '../hooks/useAddMaintenancePlan';
import useGetMaintenancePlan from '../hooks/useGetMaintenancePlan';
import useUpdateMaintenancePlan from '../hooks/useUpdateMaintenancePlan';

export interface IProps {
  show: boolean;
  onHide: () => void;
  moduleName: string;
  workspaceModuleId: string;
  maintenancePlanId: string;
}

export interface FinishPost {
  index: number;
  finishYear?: number;
  finishPrice?: number;
  finished: boolean;
}

export const UpdateMaintenancePlanModal: React.FC<IProps> = ({
  show,
  onHide,
  moduleName,
  workspaceModuleId,
  maintenancePlanId,
}) => {
  const [, , maintenancePlan] = useGetMaintenancePlan(maintenancePlanId);

  const [, , mappingObjectDetail, fetch] = useGetMappingObjectDetail(maintenancePlan?.mappingObjectId ?? '');

  const [, , , updatedPlan, update] = useUpdateMaintenancePlan();

  const [chooseAll, setChooseAll] = useState<boolean>(false);

  const [finishPostsActive, setFinishPostsActive] = useState<FinishPost[]>([]);

  const {
    control,
    register,
    getValues,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<UpdateMaintenancePlanRequest>();

  const {
    fields: values,
    append: appendValue,
    remove: removeValue,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'maintenancePlanParameterValueBases', // unique name for your Field Array
  });

  const {
    fields: measures,
    append: appendMeasure,
    remove: removeMeasure,
  } = useFieldArray({
    control,

    name: 'maintenancePlanMeasures',
  });

  const addToFinishPostsActive = (post: FinishPost) => {
    setFinishPostsActive([...finishPostsActive, post]);
  };

  const removeToFinishPostsActive = (post: FinishPost) => {
    setFinishPostsActive(finishPostsActive.filter((f, i) => f.index !== post.index));
  };

  const updateToFinishPostsActive = (post: FinishPost) => {
    console.log(getValues(`maintenancePlanMeasures.${post.index}.finishYear`));
    const posts = finishPostsActive.map((f, i) => {
      if (f.index === post.index) {
        f = post;
        return f;
      } else {
        return f;
      }
    });

    setFinishPostsActive(posts);
  };

  useEffect(() => {
    if (maintenancePlan) {
      reset({
        maintenancePlanId: maintenancePlan.maintenancePlanId,
        maintenancePlanDescription: maintenancePlan.maintenancePlanDescription,
        maintenancePlanNumber: maintenancePlan.maintenancePlanNumber,
        maintenancePlanName: maintenancePlan.maintenancePlanName,
        mappingObjectId: maintenancePlan.mappingObjectId,
        maintenancePlanParameterValueBases: maintenancePlan.maintenancePlanParameterValueBases,
        maintenancePlanMeasures: maintenancePlan.maintenancePlanMeasures,
        maintenancePlanTypeId: maintenancePlan.maintenancePlanTypeId,
        priority: maintenancePlan.priority,
        consequenceDegree: maintenancePlan.consequenceDegree,
      });

      setChooseAll(false);
      maintenancePlan.maintenancePlanMeasures?.map((row, i) => {
        if (row.finishYear != null) {
          addToFinishPostsActive({ index: i, finished: true });
        }
      });
    }
  }, [maintenancePlan, show]);

  const navigate = useNavigate();

  useEffect(() => {
    if (updatedPlan) {
      onHide();
    }
  }, [updatedPlan]);

  const addMaintenancePlan = (d: UpdateMaintenancePlanRequest) => {
    update({ id: d.maintenancePlanId ? d.maintenancePlanId : '', request: d });
  };

  useEffect(() => {
    if (mappingObjectDetail) {
      if (chooseAll) {
        mappingObjectDetail?.valueGroups?.map((x, i) => {
          x.cdValues?.map((cd, index) => {
            appendValue({ mappingParameterValueId: cd.mappingParameterValueId });
          });
        });
      } else {
        reset({ maintenancePlanParameterValueBases: [], maintenancePlanMeasures: measures });
      }
    }
  }, [chooseAll]);

  return (
    <>
      <Modal show={show} onHide={onHide} centered size="xl">
        <Modal.Header closeButton>Oppdater tiltak - {moduleName}</Modal.Header>
        <Form onSubmit={handleSubmit((d) => addMaintenancePlan(d))}>
          {mappingObjectDetail && (
            <Modal.Body>
              <>
                <Row className="border-bottom mb-3">
                  <Col>
                    <h5 className="mb-3">Tiltaksdetaljer</h5>
                    <Row className="mb-3">
                      <Col xs={4}>
                        <Form.Group>
                          <Form.Label>Parent</Form.Label>
                          <Form.Control value={mappingObjectDetail.objectParentName} readOnly></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col xs={8}>
                        <Form.Group>
                          <Form.Label>Child</Form.Label>
                          <Form.Control value={mappingObjectDetail.objectName} readOnly></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={8}>
                        <Form.Group>
                          <Form.Label>Prosjektnavn</Form.Label>
                          <Form.Control
                            placeholder="Fasadeprosjekt"
                            {...register('maintenancePlanName')}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col xs={4}>
                        <Form.Group>
                          <Form.Label>Prosjektnummer</Form.Label>
                          <Form.Control
                            placeholder="123"
                            type="number"
                            {...register('maintenancePlanNumber')}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={6}>
                        <Form.Group>
                          <Form.Label>Tiltakstype</Form.Label>
                          <Form.Select {...register('maintenancePlanTypeId')}>
                            <option value={MaintenancePlanType.Sikkerhet} selected>
                              {MaintenancePlanType.Sikkerhet}
                            </option>
                            <option value={MaintenancePlanType.Konomi}>{MaintenancePlanType.Konomi}</option>
                            <option value={MaintenancePlanType.Estetikk}>{MaintenancePlanType.Estetikk}</option>
                            <option value={MaintenancePlanType.Milj}>{MaintenancePlanType.Milj}</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col xs={3}>
                        <Form.Group>
                          <Form.Label>Konsekvensgrad</Form.Label>
                          <Form.Select {...register('consequenceDegree')}>
                            <option value={0} selected>
                              0
                            </option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col xs={3}>
                        <Form.Group>
                          <Form.Label>Prioritet</Form.Label>
                          <Form.Control placeholder="1" type="number" {...register('priority')}></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col>
                        <Form.Group>
                          <Form.Label>Beskrivelse</Form.Label>
                          <Form.Control as="textarea" {...register('maintenancePlanDescription')}></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <h5 className="mb-3">Dagens status og parameter</h5>
                    <Row className="mb-3">
                      <Col>
                        <Form.Check
                          label="Velg alle parametere"
                          checked={chooseAll}
                          onChange={() => {
                            setChooseAll(!chooseAll);
                          }}
                        ></Form.Check>
                      </Col>
                      <Col className="d-flex justify-content-end"></Col>
                    </Row>
                    <Row style={{ height: '500px', overflow: 'scroll' }}>
                      <Col xs={12}>
                        <>
                          {mappingObjectDetail.valueGroups &&
                            mappingObjectDetail.valueGroups
                              .sort((a, b) => {
                                return a.sortOrder - b.sortOrder;
                              })
                              .map((row, i) => (
                                <>
                                  {row.cdValues &&
                                    row.cdValues
                                      .sort((a, b) => {
                                        return a.sortOrder - b.sortOrder;
                                      })
                                      .map((cd, index) => (
                                        <Card className="p-3 mb-3">
                                          <Row>
                                            <Col>
                                              <Form.Check
                                                value={cd.mappingParameterValueId}
                                                label={cd.mappingParameterName}
                                                checked={values.some(
                                                  (x) => x.mappingParameterValueId == cd.mappingParameterValueId,
                                                )}
                                                onChange={() => {
                                                  if (
                                                    values.some(
                                                      (x) => x.mappingParameterValueId == cd.mappingParameterValueId,
                                                    )
                                                  ) {
                                                    const planIndex = values.findIndex(
                                                      (x) => x.mappingParameterValueId == cd.mappingParameterValueId,
                                                    );
                                                    removeValue(planIndex);
                                                  } else {
                                                    appendValue({
                                                      mappingParameterValueId: cd.mappingParameterValueId,
                                                    });
                                                  }
                                                  console.log(values);
                                                }}
                                              ></Form.Check>
                                            </Col>
                                            {cd.childValues && cd.childValues.length > 0 ? (
                                              cd.childValues.map((child, indexChild) => (
                                                <Row className="border-bottom py-2">
                                                  <Col>
                                                    <small>{child.mappingParameterName}</small>
                                                  </Col>
                                                  <Col className="d-flex justify-content-end">
                                                    <ConditionDegreeIcon conditionDegree={parseInt(child.value!)} />
                                                    <small className="ms-1">
                                                      {parseInt(child.value!) != 99
                                                        ? parseInt(child.value!).toFixed(1)
                                                        : 'N/A'}
                                                    </small>
                                                  </Col>
                                                </Row>
                                              ))
                                            ) : (
                                              <Col className="d-flex justify-content-end">
                                                <div className="d-flex align-items-center">
                                                  <ConditionDegreeIcon conditionDegree={parseInt(cd.value!)} />
                                                  <small className="ms-1">
                                                    {' '}
                                                    {parseInt(cd.value!) != 99 ? parseInt(cd.value!).toFixed(1) : 'N/A'}
                                                  </small>
                                                </div>
                                              </Col>
                                            )}
                                          </Row>
                                        </Card>
                                      ))}
                                </>
                              ))}
                        </>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <h5>Oppstart og kostnad</h5>
                  </Col>
                  <Col className="d-flex justify-content-end">
                    <Button
                      variant="tertiary"
                      onClick={() => {
                        appendMeasure({
                          startYear: new Date().getFullYear(),
                          budgetYear: new Date().getFullYear(),
                          priceEstimate: 0,
                          priceCalculate: 0,
                          priceFinish: null,
                          finishYear: null,
                        });
                      }}
                    >
                      Legg til ny oppstart og kostnads post
                    </Button>
                  </Col>
                </Row>
                {measures.map((measure, i) => (
                  <Row className="p-3 mx-1 my-3 border rounded">
                    <Row className="mb-4">
                      <Col>
                        <span>{i}</span>
                      </Col>
                      {finishPostsActive.some((post) => i == post.index && !post.finished) ? (
                        <Col className="d-flex justify-content-end align-items-end">
                          <Button
                            variant="tertiary"
                            className="me-2"
                            size="sm"
                            onClick={() => removeToFinishPostsActive({ index: i, finished: false })}
                          >
                            Avbryt
                          </Button>
                          <Button
                            variant="tertiary"
                            size="sm"
                            onClick={() => updateToFinishPostsActive({ index: i, finished: true })}
                          >
                            Lagre
                          </Button>
                        </Col>
                      ) : (
                        <Col className="d-flex justify-content-end align-items-end">
                          <>
                            <Button
                              variant="tertiary"
                              size="sm"
                              onClick={() => {
                                addToFinishPostsActive({ index: i, finished: false });
                              }}
                              disabled={finishPostsActive.some((post) => i == post.index && post.finished)}
                            >
                              Avslutt post
                            </Button>
                          </>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>Planlagt startår</Form.Label>
                          <Form.Select
                            {...register(`maintenancePlanMeasures.${i}.startYear`)}
                            disabled={finishPostsActive.some((post) => i == post.index)}
                          >
                            {arrayRange(2000, 2100, 1).map((year, yearIndex) => (
                              <option value={year}>{year}</option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Budsjettert år</Form.Label>
                          <Form.Select
                            {...register(`maintenancePlanMeasures.${i}.budgetYear`)}
                            disabled={finishPostsActive.some((post) => i == post.index)}
                          >
                            {arrayRange(2000, 2100, 1).map((year, yearIndex) => (
                              <option value={year}>{year}</option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Estimert pris</Form.Label>
                          <Form.Control
                            type="number"
                            {...register(`maintenancePlanMeasures.${i}.priceEstimate`)}
                            disabled={finishPostsActive.some((post) => i == post.index)}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Kalkulert pris</Form.Label>
                          <Form.Control
                            type="number"
                            {...register(`maintenancePlanMeasures.${i}.priceCalculate`)}
                            disabled={finishPostsActive.some((post) => i == post.index)}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      {finishPostsActive.some((post) => i == post.index) && (
                        <>
                          <Col>
                            <Form.Group>
                              <Form.Label>Avsluttet år</Form.Label>
                              <Form.Select
                                disabled={
                                  measure.finishYear != undefined ||
                                  finishPostsActive.find((x) => x.index == i)?.finished
                                }
                                {...register(`maintenancePlanMeasures.${i}.finishYear`)}
                              >
                                {arrayRange(measure.startYear!, 2100, 1).map((year, yearIndex) => (
                                  <option value={year}>{year}</option>
                                ))}
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group>
                              <Form.Label>Avsluttet pris</Form.Label>
                              <Form.Control
                                type="number"
                                {...register(`maintenancePlanMeasures.${i}.priceFinish`)}
                                disabled={
                                  measure.finishYear != undefined ||
                                  finishPostsActive.find((x) => x.index == i)?.finished
                                }
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                        </>
                      )}
                    </Row>
                  </Row>
                ))}
              </>
            </Modal.Body>
          )}

          <Modal.Footer>
            <Button
              variant="tertiary"
              onClick={() => {
                onHide();
                reset();
                setFinishPostsActive([]);
              }}
            >
              Avbryt
            </Button>
            <Button variant="secondary" type="submit">
              Oppdater
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};
