import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import MapIcon from '@mui/icons-material/Map';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ViewStreamSvg } from '../../../assets/icons/view_stream.svg';
import styles from './footer.module.scss';

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className={`${styles.container}`}>
      <Container fluid className="d-none d-lg-block">
        <Row>
          <Col className="col-auto">
            <ViewStreamSvg className="size-xl p-3" />
          </Col>
          <Col>
            <div className="mx-3">
              <small>
                {t('developed-by')}&nbsp;
                <u>Multiconsult</u>
              </small>
            </div>
          </Col>
          <Col>
            <MapIcon fontSize="medium" className=" m-3" />
            <small>{t('multi-address')}</small>
          </Col>
          <Col>
            <EmailIcon fontSize="medium" className=" m-3" />
            <small>multimap@multiconsult.no</small>
          </Col>
          <Col>
            <CallIcon fontSize="medium" className="m-3" />
            <small>21 58 50 00</small>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
