import { Col, Container, Row } from 'react-bootstrap';
import { Outlet } from 'react-router';

import { Sidebar } from './components/sidebar';

export const Learning = () => {
  return (
    <>
      <Row className="h-100 g-0">
        <Col className="col-md-2 d-none d-md-block">
          <Sidebar />
        </Col>
        <Col id="page-content-wrapper" className="col-sm-12 col-md-10 pt-5 pe-sm-3 pe-lg-5 ps-sm-3 ps-lg-5">
          <Container fluid>
            <Outlet />
          </Container>
        </Col>
      </Row>
    </>
  );
};
